import { useState, useEffect } from "react";
import AuthContext from "./AuthContext";
import axios from "axios";
const AuthProvider = ({ children }) => {
  const [userState, setUserState] = useState({});
  const authToken = localStorage.getItem("auth-token");

  async function currentUser() {

    var config = {
      method: 'GET',
      url: process.env.REACT_APP_API_SERVER + "/api/v1/admin/getdetails",
      headers: { 
        'auth-token': authToken, 
      },
    };
    
    axios(config)
    .then(function (response) {
    setUserState(response.data);
    })
    .catch(function (error) {
      // console.log(error);
    });
    
  }

  useEffect(() => {
    currentUser();
  }, []);

  return (
    <AuthContext.Provider value={userState}>{children}</AuthContext.Provider>
  );

};

export default AuthProvider;
