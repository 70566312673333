import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Button, Nav } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ReactPaginate from "react-paginate";

// imges
import axios from "axios";

import Spinner from "../HelperUi/Spinner";

const AllUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatedValuers, setUpdatedValuers] = useState([]);
  const [allValuers, setAllValuer] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const authToken = localStorage.getItem("auth-token");
  const navigate = useNavigate();

  const getAllUsers = async () => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/valuer/getall",
      headers: {
        "auth-token": authToken,
      },
    };

    axios(config)
      .then(function (response) {
        setAllValuer(response.data);
        setUpdatedValuers(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleUserDelete = (id) => {
    var config = {
      method: "delete",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/auth/deletemember/" + id,
      headers: {
        "auth-token": authToken,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response);
        getAllUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const viewMember = (id) => {
    navigate("/view-valuer", { state: { memberId: id } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSearchChange = (e) => {
    if (!e.target.value) return setUpdatedValuers(allValuers);
    const resultArray = allValuers.filter(
      (user) =>
        user.valuer_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        user.establishment_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        user.account_status.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setUpdatedValuers(resultArray);
  };

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const displayValuers = updatedValuers
    .slice(pagesVisited, pagesVisited + usersPerPage)
    .map((user, i) => {
      return (
        <tr>
          <td>{i + 1}</td>
          <td>{user.valuerID}</td>
          <td>{user.valuer_name}</td>
          <td>{user.establishment_name}</td>
          <td>
            {user.country_code}
            {user.mobile_number}
          </td>

          <td>{user.serviceable_radius}</td>

          <td>{user.account_status}</td>
          <td>
            <span className="row">
              <span className="col-6">
                <Button
                  type="button"
                  className=" btn-sm p-2"
                  onClick={() => {
                    viewMember(user._id);
                  }}
                >
                  <i className="flaticon-381-view-2"></i>
                </Button>
              </span>
              <span className="col-6">
                <Button
                  as="a"
                  variant=""
                  onClick={() => handleUserDelete(user._id)}
                  className="btn-danger btn-sm p-2"
                >
                  <i className="flaticon-381-trash-1"></i>
                </Button>
              </span>
            </span>
          </td>
        </tr>
      );
    });
  const pageCount = Math.ceil(updatedValuers.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="card overflow-hidden  w-100">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 text-left align-self-center">
              <h3 className="text-primary text-left">All valuers</h3>
            </div>
            <div className="col-lg-6 text-right">
              <form
                onSubmit={handleSubmit}
                className=" d-flex justify-content-end"
              >
                <div className="input-group search-area">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <Table hover responsive style={{ color: "#000" }}>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>ID</th>
                <th>Valuer Name</th>
                <th>Establishment Name</th>
                <th>Mobile Number</th>
                <th>Serviceable Radius</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {displayValuers.length > 0 ? (
                displayValuers
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Valuers Data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {displayValuers.length > 0 && (
            <div className="w-auto text-end">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons w-auto"}
                previousLinkClassName={"prevButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"disabledButton"}
                activeClassName={"activeButton"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllUsers;
