import { ColorRing } from "react-loader-spinner";
import Container from "react-bootstrap/Container";

const Spinner = () => {


    return (
    <>
      <Container>
        <div className="card overflow-hidden   w-100">
          <div className="card-body">
            <div className="text-center">
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
};

export default Spinner;
