import React from "react";
import ProtectedRoute from "./protected";

/// React router dom
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./components/nav";
import Footer from "./components/nav/Footer";

import AuthProvider from "./components/ContextApi/AuthProvider";

/// Pages
import PageLogin from "./components/Authentication/Login";
import Dashboard from "./components/Dashboard/Home";
import AllUsers from "./components/Valuers/AllUsers";
import ViewUser from "./components/Valuers/ViewUser";
import AllBanks from "./components/BanksBranches/AllBanks";
import AllBranches from "./components/BanksBranches/AllBranches";

const Markup = () => {
  return (
    <AuthProvider>
      <Router basename="/">
        <div id="main-wrapper" className="show">
          <Routes>
            <Route path="/">
              <Route index element={<PageLogin />} />

              {/* ////////////ptotected//////////////// */}
              <Route element={<ProtectedRoute />}>
                <Route
                  path="dashboard"
                  element={
                    <div>
                      <Nav />
                      <div className="content-body">
                        <div className="container-fluid">
                          <Dashboard />
                        </div>
                      </div>
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="all-valuers"
                  element={
                    <div>
                      <Nav />
                      <div className="content-body">
                        <div className="container-fluid">
                          <AllUsers />
                        </div>
                      </div>
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="view-valuer"
                  element={
                    <div>
                      <Nav />
                      <div className="content-body">
                        <div className="container-fluid">
                          <ViewUser />
                        </div>
                      </div>
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="all-banks"
                  element={
                    <div>
                      <Nav />
                      <div className="content-body">
                        <div className="container-fluid">
                          <AllBanks />
                        </div>
                      </div>
                      <Footer />
                    </div>
                  }
                />
                <Route
                  path="all-branches"
                  element={
                    <div>
                      <Nav />
                      <div className="content-body">
                        <div className="container-fluid">
                          <AllBranches />
                        </div>
                      </div>
                      <Footer />
                    </div>
                  }
                />
              </Route>
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default Markup;
