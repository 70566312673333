import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Table, Modal } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import Spinner from "../HelperUi/Spinner";
import { toast, ToastContainer } from "react-toastify";

const AllBanks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddBank, setIsLoadingAddBank] = useState(false);
  const [updatedBanks, setUpdatedBanks] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [bankName, setBankName] = useState("");
  const [bankLogo, setBankLogo] = useState();
  const authToken = localStorage.getItem("auth-token");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addBank = () => {
    setIsLoadingAddBank(true);
    const FormData = require("form-data");
    let data = new FormData();
    data.append("bank_name", bankName);
    data.append("bank_logo", bankLogo);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_SERVER + "/api/v1/bank/add",
      headers: {
        "auth-token": authToken,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Bank added successfully");
        setBankName("");
        setIsLoadingAddBank(false);
        setShow(false);
        getAllBanks();
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        setIsLoadingAddBank(false);
        console.log(error);
      });
  };

  const getAllBanks = async () => {
    // setIsLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/bank/getBanks",
      headers: {
        "auth-token": authToken,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        setAllBanks(response.data.data);
        setUpdatedBanks(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSearchChange = (e) => {
    if (!e.target.value) return setUpdatedBanks(allBanks);
    const resultArray = allBanks.filter((user) =>
      user.bank_name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setUpdatedBanks(resultArray);
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="card overflow-hidden  w-100">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4 text-left align-self-center">
              <h3 className="text-primary text-left">All Banks</h3>
            </div>
            <div className="col-lg-8 text-right d-flex justify-content-end">
              <form
                onSubmit={handleSubmit}
                className=" d-flex justify-content-end"
              >
                <div className="input-group search-area">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here"
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                          fill="#A4A4A4"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </form>
              <button className="btn btn-primary ml-2" onClick={handleShow}>
                Add Banks
              </button>
            </div>
          </div>

          <Table hover responsive style={{ color: "#000" }}>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Bank ID</th>
                <th>Bank Name</th>
                <th>Bank Logo</th>
              </tr>
            </thead>

            <tbody>
              {updatedBanks.length > 0 ? (
                updatedBanks.map((bank, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{bank._id}</td>
                      <td>{bank.bank_name}</td>
                      <td>
                        <img
                          src={bank.bank_logo}
                          alt={bank.bank_name}
                          className="img-fluid"
                          style={{ height: "50px" }}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data available
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="bank_name" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                className="form-control"
                id="bank_name"
                name="bank_name"
                defaultValue={bankName}
                onChange={(e) => setBankName(e.target.value)}
                placeholder="Enter the bank name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="bank_name" className="form-label">
                Bank Logo
              </label>
              <input
                type="file"
                className="form-control"
                id="bank_logo"
                name="bank_logo"
                onChange={(e) => setBankLogo(e.target.files[0])}
                placeholder="choose the bank name"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-link" onClick={handleClose}>
            Close
          </button>
          <Button variant="primary" onClick={addBank}>
            {isLoadingAddBank ? (
              <ClipLoader
                className="spin-loader"
                color={"#fff"}
                loading={isLoadingAddBank}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              "Add Bank"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllBanks;
