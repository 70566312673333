import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Button, Nav, Input } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Spinner from "../HelperUi/Spinner";
import { ToastContainer, toast } from "react-toastify";

const ViewUser = () => {
  const authToken = localStorage.getItem("auth-token");
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [accStatus, setAccStatus] = useState("");
  const [update, setUpdate] = useState(false);
  const location = useLocation();

  const onChangeValue = (event) => {
    setUpdate(true);
    setAccStatus(event.target.value);
  };
  const getUser = () => {
    var config = {
      method: "GET",
      url:
        process.env.REACT_APP_API_SERVER +
        "/api/v1/valuer/getall/" +
        location.state.memberId,
      headers: {
        "auth-token": authToken,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setUserData(response.data[0]);
        setAccStatus(response.data[0].account_status);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateUser = async () => {
    var data = JSON.stringify({
      account_status: accStatus,
    });

    var config = {
      method: "put",
      url:
        process.env.REACT_APP_API_SERVER +
        "/api/v1/valuer/valuerupdate/" +
        location.state.memberId,
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // setUpdatedInfo(true);
        toast.success("Valuer Upadated Successfully!!");
        console.log(JSON.stringify(response.data));
        setUpdate(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Row>
          <Col lg="12">
            <div className="card overflow-hidde shadow w-100">
              <div className="card-body">
                <h3 className="text-primary text-left">Member Details</h3>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label htmlFor="vid">Valuer ID</label>
                    <input
                      id="vid"
                      type="text"
                      className="form-control"
                      value={userData.valuerID}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="name">Valuer Name</label>
                    <input
                      id="name"
                      type="text"
                      className="form-control"
                      value={userData.valuer_name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-6">
                    <label htmlFor="estname">Establishment Name</label>
                    <input
                      id="estname"
                      type="text"
                      className="form-control"
                      value={userData.establishment_name}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="estSince">Establishment Since</label>
                    <input
                      id="estSince"
                      type="text"
                      className="form-control"
                      value={moment(
                        new Date(userData.established_since)
                      ).format("MM-DD-YYYY")}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-6">
                    <label for="code">Country Code</label>
                    <input
                      id="ccode"
                      type="text"
                      className="form-control"
                      value={userData.country_code}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="mobilenumber">Mobile Number</label>
                    <input
                      id="mobilenumber"
                      type="text"
                      className="form-control"
                      value={userData.mobile_number}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-12 from-group">
                    <label htmlFor="address">Address</label>
                    <textarea
                      name="address"
                      id="address"
                      className="form-control"
                      cols={12}
                      rows={2}
                      readOnly
                    >
                      {userData.address}
                    </textarea>
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-6">
                    <label for="pincode">PinCode</label>
                    <input
                      id="pincode"
                      type="text"
                      className="form-control"
                      value={userData.pincode}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="city">City</label>
                    <input
                      id="city"
                      type="text"
                      className="form-control"
                      value={userData.city}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-6">
                    <label for="state">State</label>
                    <input
                      id="state"
                      type="text"
                      className="form-control"
                      value={userData.state}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="country">Country</label>
                    <input
                      id="country"
                      type="text"
                      className="form-control"
                      value={userData.country}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-6">
                    <label for="serviceable_radius">Serviceable Radius</label>
                    <input
                      id="serviceable_radius"
                      type="text"
                      className="form-control"
                      value={userData.serviceable_radius}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="date_of_joining">Date Of Joining</label>
                    <input
                      id="date_of_joining"
                      type="text"
                      className="form-control"
                      value={moment(new Date(userData.date_of_joining)).format(
                        "MM-DD-YYYY"
                      )}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row  mt-3">
                  <div className="col-md-12">
                    <label for="accstatus">Account Status </label>
                    <div className="row">
                      <div className="col-12" onChange={onChangeValue}>
                        <input
                          type="radio"
                          id="pending"
                          name="accstatus"
                          className="ml-2"
                          value={"Pending"}
                          defaultChecked={accStatus === "Pending"}
                        />
                        <label htmlFor="pending" className="ml-2">
                          Pending
                        </label>
                        <input
                          type="radio"
                          id="active"
                          name="accstatus"
                          className="ml-2"
                          value={"Active"}
                          defaultChecked={accStatus === "Active"}
                        />
                        <label htmlFor="active" className="ml-2">
                          Active
                        </label>
                        <input
                          type="radio"
                          id="blocked"
                          name="accstatus"
                          className="ml-2"
                          value={"Blocked"}
                          defaultChecked={accStatus === "Blocked"}
                        />
                        <label htmlFor="blocked" className="ml-2">
                          Blocked
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {update && (
                  <Button
                    as="a"
                    variant="secondary light"
                    href="#"
                    className="btn-card mt-3"
                    onClick={updateUser}
                  >
                    Update User
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewUser;
