import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";

const PageLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#fff");
  const navigate = useNavigate();
  const authToken = localStorage.getItem("auth-token");

  const handleSubmit = () => {
    setLoading(true);
    var data = JSON.stringify({
      email_id: email,
      password: password,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/admin/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem("auth-token", response.data.authtoken);
          localStorage.setItem("isAuthenticated", "true");
          toast.success("Login Success", {
            onClose: () => {
              navigate("/dashboard");
            },
          });
        } else if (response.data.success === false) {
          toast.error("Invalid Email or Password", {
            onClose: () => setLoading(false),
          });
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong Try again");
      });
  };
  useEffect(() => {
    if (localStorage.getItem("auth-token")) {
      navigate("/dashboard");
      console.log("authToken" + authToken);
    }
  }, []);
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="container">
        <div className="row min-vh-100 justify-content-center align-items-center">
          <div className="col-lg-6 p-0">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4 font-select">
                      Sign in to your SuperBank Admin account
                    </h4>
                    <form>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          // defaultValue="hello@example.com"
                          name="Email"
                          defaultValue={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          // defaultValue="Password"
                          name="password"
                          defaultValue={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my preference
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={() => handleSubmit()}
                        >
                          {!loading ? (
                            "Sign Me In"
                          ) : (
                            <ClipLoader
                              className="spin-loader"
                              color={"#fff"}
                              loading={loading}
                              size={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
