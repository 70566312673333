import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    this.mm("dispose");
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  constructor(props) {
    super(props);
  }

  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }

  render() {
    const Logout = () => {
      window.localStorage.removeItem("auth-token");
      window.localStorage.removeItem("isAuthenticated");
      this.props.navigation.navigate("/");
    };
    /// Path
    const path = window.location.pathname;
    let dashboard = ["dashboard"],
      valuers = ["all-valuers", "view-valuer"],
      bankers = ["all-bankers", "view-banker"],
      banks = ["all-banks", "all-branches"];
    return (
      <div className="deznav bg-white">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li
              className={`${
                dashboard.includes(path.slice(1)) ? "mm-active" : ""
              }`}
            >
              <Link className="" to="/dashboard">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${
                valuers.includes(path.slice(1)) ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Valuers</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/all-valuers">All Valuers</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${
                bankers.includes(path.slice(1)) ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-user"></i>
                <span className="nav-text">Bankers</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/all-bankers">All Bankers</Link>
                </li>
              </ul>
            </li>
            <li
              className={`${banks.includes(path.slice(1)) ? "mm-active" : ""}`}
            >
              <Link className="has-arrow ai-icon" to="#" aria-expanded="false">
                <i className="flaticon-381-home-1"></i>
                <span className="nav-text">Banks & Branches</span>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/all-banks">All Banks</Link>
                </li>
                <li>
                  <Link to="/all-branches">All Branches</Link>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0)" onClick={Logout}>
                <i className="flaticon-381-enter"></i>
                <span className="nav-text">Logout</span>
              </a>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
