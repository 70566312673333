import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card, Button, Nav } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";

// imges
import xd from "../../../images/admin/xd.png";
import users from "../../../images/admin/users.png";
import settings from "../../../images/admin/settings.png";
import notification from "../../../images/admin/notification.png";
import money from "../../../images/admin/money.png";
import dice from "../../../images/tournaments/dice.png";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allValuer, setAllValuer] = useState([]);
  const [allBankers, setAllBankers] = useState([]);
  const [allDeposits, setAllDeposits] = useState([]);
  const [depositAmountArray, setDepositAmountArray] = useState([]);
  const [depositAmount, setDepositAmount] = useState();

  const authToken = localStorage.getItem("auth-token");

  const getAllValuers = async () => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/valuer/getall",
      headers: {
        "auth-token": authToken,
      },
    };

    axios(config)
      .then(function (response) {
        setAllValuer(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAllBankers = async () => {
    setIsLoading(true);
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_SERVER + "/api/v1/banker/getall",
      headers: {
        "auth-token": authToken,
      },
    };

    axios(config)
      .then(function (response) {
        setAllBankers(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getAllDeposits = async () => {};

  useEffect(() => {
    getAllValuers();
    getAllBankers();
    getAllDeposits();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col lg="6">
            <div className="card overflow-hidden w-100 shadow-sm">
              <div className="card-body">
                <div className="row p-2">
                  <div className="col-4">
                    <img
                      src={users}
                      alt="img"
                      className="img-fluid"
                      width="85px"
                    />
                  </div>
                  <div className="col-6 ">
                    <p className="text-primary mb-0 font-weight-bold">
                      TOTAL VALUERS
                    </p>
                    <h3 className="">{allValuer.length}</h3>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="card overflow-hidden  w-100 shadow-sm">
              <div className="card-body">
                <div className="row p-2">
                  <div className="col-4">
                    <img
                      src={dice}
                      alt="img"
                      className="img-fluid"
                      width="85px"
                    />
                  </div>
                  <div className="col-6 ">
                    <p className="text-primary mb-0 font-weight-bold">
                      TOTAL BANKERS
                    </p>
                    <h3 className="">{allBankers.length}</h3>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
